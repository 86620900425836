/* Reset margins and paddings */
* {
  margin: 0;
  padding: 0;
}
/* Set html and body to full height */
html, body {
  height: 100%;
  background: grey;
}
#App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
/* Header and footer styles */
header, footer {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
  flex-shrink: 0;
}
/* Main content area */
main {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}
/* Each section takes equal space */
section {
  flex: 1;
  overflow: hidden;
}
/* Responsive adjustments */
@media (max-width: 500px) {
  main {
    grid-template-columns: repeat(auto-fit, minmax(100vw, 1fr));
  }
}
/* Ensure iframes fill their containers */
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
/* Footer links styling */
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
}
footer nav {
  display: flex;
  justify-content: center;
  gap: 20px;
}
footer a {
  text-decoration: none;
  color: #000;
  margin: 0;
}
footer a:hover {
  text-decoration: underline;
}

footer a.active {
  text-decoration: underline;
}

#ASL {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#asl-zoom-meeting {
  align-self: stretch;
  display: flex;
}

#caption-font-size-selector {
  margin-left: 8px;
}
